
'use client'
import dynamic from "next/dynamic"

const ReduxProvider = dynamic(() => import("@/lib/redux-provider"), {
  ssr: false
})

const Header = dynamic(() => import("@/components/header"), {
  ssr: false
})

const SideNavbar = dynamic(() => import("@/components/side-navbar"), {
  ssr: false
})

const ScrollTop = dynamic(() => import("@/components/ui/scroll-top"), {
  ssr: false
})

export default function RootLayoutPage({
  children,
}: Readonly<{
  children: React.ReactNode
}>) {
  
  return (       
    <ReduxProvider>
        <div className='w-full flex flex-col items-center h-full'>
            <Header /> 
            <SideNavbar />
            <main className='flex-grow w-full md:w-full lg:w-11/12 mx-auto'>
                {children}
            </main> 
            <ScrollTop />
        </div> 
    </ReduxProvider>    
  )
}
